import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Logo } from '../Logo';
import { Footer } from '../Footer';
import { SwitchLanguageButton } from '../SwitchLanguageButton';

export const PublicMainLayout: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  return (
    <div className="d-flex flex-column min-vh-100">
      <header className="container position-relative">
        <div className="p-5 d-flex justify-content-center">
          <Logo />
        </div>

        <div className="position-absolute top-0 end-0 mt-2 mt-md-4 me-3">
          <SwitchLanguageButton
            lang={i18n.resolvedLanguage}
            onChange={(locale) => i18n.changeLanguage(locale)}
          />
        </div>
      </header>

      <main className="flex-grow-1">
        <Outlet />
      </main>

      {location.pathname !== '/application' && (
        <footer className="mt-auto">
          <Footer />
        </footer>
      )}
    </div>
  );
};
